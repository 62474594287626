<template>
  <CDropdown
    inNav
    class="c-header-nav-items"
    placement="bottom-end"
    add-menu-classes="pt-0"
  >
    <template #toggler>
      <CHeaderNavLink>
        <div class="profile-link"><div ><div class="fs-12 fw-800">{{ name }}</div>
        <div class="text-muted fs-11">{{ site }}</div></div>
         <CIcon class="ml-4 profile-avatar" name="cil-user"/>
      </div>
      </CHeaderNavLink>
    </template>
    <CDropdownItem>
      <CIcon name="cil-user" /> Profil
    </CDropdownItem>
    <CDropdownItem @click="logout">
      <CIcon name="cil-lock-locked" /> Odhlásiť
    </CDropdownItem>
  </CDropdown>
</template>

<script>

    import {userService} from "../_services";
    import {API_URL} from "../api";
    import {dataService} from "../_services";

export default {
  name: 'TheHeaderDropdownAccnt',
  data () {
    return {
      itemsCount: 42,
       activeNotifications: false,
                showMenu: false,
                send: API_URL,
                searchQuery: '',
                site:'',
                name: '',
                searchResults:[],
                avatar: '',
          username: JSON.parse(localStorage.user).data.name
    }
  },
    mounted: function() {
            if (localStorage.user) {
                this.name = JSON.parse(localStorage.user).data.name;
                this.avatar = JSON.parse(localStorage.user).data.avatar;
                this.site = JSON.parse(localStorage.user).data.site_title;
            }
            window.addEventListener("storage", this.onStorageUpdate);
        },
            methods: {
            findItAll(e)
            {
              if(e.length > 2)
            {dataService.axiosPost('findnodes', {searchterm: e}).then(results => {

              this.searchResults = results.data.data;
            });
            }
              else
              {this.searchResults = [];}

              },
            onStorageUpdate(event) {
                if (event.key === "user") {
                    this.name = JSON.parse(localStorage.user).data.name;
                    this.avatar = JSON.parse(localStorage.user).data.avatar;
                }

            },
                findItAll(e)
            {
              if(e.length > 2)
            {dataService.axiosPost('findnodes', {searchterm: e}).then(results => {

              this.searchResults = results.data.data;
            });
            }
              else
              {this.searchResults = [];}

              },
            beforeDestroy() {
                window.removeEventListener("storage", this.onStorageUpdate);
            },
            logout() {
                userService.logout()


            },
            toggleSidebar() {
                this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
            },
            hideSidebar() {
                this.$sidebar.displaySidebar(false);
            },
            toggleMenu() {
                this.showMenu = !this.showMenu;
            }
        }
}
</script>

<style scoped>
  .c-icon {
    margin-right: 0.3rem;
  }

   .navbar-search-dark .input-group-text {
        color: #1d2226 !important;
    }
    .navbar-search
    {position:relative;}
.navbar-search .searchoutput
{display:none;
    overflow:hidden;
    position:absolute;
    width: 93%;
    top: 32px;
    left:10px;
    padding-top: 10px;background-color:#fff;}
    .navbar-search .searchoutput .ant-list-bordered {
        border: 0;
        border-radius: 4px;
    }
    .navbar-search .searchoutput:hover
    {display:block;}
    .navbar-search:hover .searchoutput
    {display:block;}
    .navbar-search .searchoutput .nav-link
    {width:100%; font-size:13px;line-height:25px;}
    .navbar-search:hover .searchoutput
    {display:inline-block;}

.navbar-search-dark .form-control::-webkit-input-placeholder { /* Edge */
        color: rgba(29, 34, 38, 0.65) !important;
    }

    .navbar-search-dark .form-control:-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: rgba(29, 34, 38, 0.65) !important;
    }
    .navbar-search .form-control
{height: 35px!important; border: 0!important;}
    .navbar-search-dark .form-control::placeholder {
        color: rgba(29, 34, 38, 0.65) !important;
    }

    .bundle-node:before
    {content: ' ';
        display:block;
        position:absolute;
        left:0;
        height:100%;
        border-right:4px solid #a8f2ff;}
    .bundle-taxonomy:before
    {content: ' ';
        display:block;
        position:absolute;
        left:0;
        height:100%;
        border-right:4px solid #83ffa6;}
</style>
