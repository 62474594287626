<template>
  <CHeader fixed with-subheader light>

    <CHeaderBrand class="mx-auto d-lg-none" to="/">
      <CIcon name="logo" height="48" alt="Logo"/>
    </CHeaderBrand>
    <CHeaderNav class="d-md-down-none mr-auto">
      <form class="navbar-search navbar-search-dark text-black-50 form-inline ml-3 d-md-flex ">
            <div class="form-group mb-0">
                <a-input placeholder="Vyhľadávanie"
                            class="input-group-alternative search-small"
                            alternative=""
                            v-model="searchQuery"
                            @change="findItAll"
                            addon-right-icon="fad fa-search">
                </a-input>
                <div class="searchoutput" v-if="searchResults.length > 0">
                    <a-list size="small" bordered :data-source="searchResults">
                        <a-list-item slot="renderItem" slot-scope="item">
                           <router-link v-if="item.bundle === 'node'" :to="'/edit-content/' + item.entity_id"  :class="'nav-link bundle-' + item.bundle"  :href="'/edit-content/' + item.entity_id"> {{ item.value }}</router-link>
                           <router-link v-if="item.bundle === 'taxonomy'" :to="'/edit-taxonomy-item/' + item.entity_id + '/' + item.content_type"  :class="'nav-link bundle-' + item.bundle"  :href="'/edit-taxonomy-item/' + item.entity_id + '/' + item.content_type"> {{item.voc}} / {{ item.value }}</router-link>
                        </a-list-item>
                    </a-list>
                </div>
            </div>
        </form>
    </CHeaderNav>
    <CHeaderNav class="mr-4">

      <TheHeaderDropdownAccnt :user="user"/>
    </CHeaderNav>
  </CHeader>
</template>

<script>
import TheHeaderDropdownAccnt from './TheHeaderDropdownAccnt'
    import Vue from "vue";
 import {Menu} from 'ant-design-vue';
    import {dataService} from "../_services";
    import {List} from 'ant-design-vue';

    Vue.use(Menu);
export default {
  name: 'TheHeader',
  components: {
    TheHeaderDropdownAccnt,
    "a-list": List
  },
  data() {
            return {
                sidebarBackground: 'vue',
                contenttypes: [],
                taxonomies: [],
                searchQuery: '',
                collapsed: false,
                unitType: "",
                openKeys: [],
                fieldData: {},
                user:{},
                searchResults:[],
                loggedIn: localStorage.getItem('user'),
                roles: [],
                paths: [],
                ctypes: [],
                selectedKeys: []
            };
        },
     mounted() {
      this.user = JSON.parse(this.loggedIn);
            if(this.$router.currentRoute.name.startsWith("wellness"))
                this.openKeys = ['wellnessstat'];
            if(this.$router.currentRoute.name.startsWith("congress"))
                this.openKeys = ['congress'];
            this.selectedKeys = [this.$router.currentRoute.name];
            const loggedIn = localStorage.getItem('user');
            const user = JSON.parse(loggedIn);
            this.roles = user.data.type_id;
            this.paths = user.data.paths;
            this.unitType = user.data.unitType;
            this.ctypes = user.data.ctypes;
        },
          methods: {
            toggleSidebar() {
                if (this.$sidebar.showSidebar) {
                    this.$sidebar.displaySidebar(false);
                }
            },
            titleClick() {
                if(this.$router.currentRoute.name.startsWith("wellness"))
                    this.openKeys = ['wellnessstat'];
                if(this.$router.currentRoute.name.startsWith("congress"))
                    this.openKeys = ['congress'];
            },
            findItAll(ef)
            {

var e = ef.target.value;
              if(e.length > 2)
            {dataService.axiosPost('findnodes', {searchterm: e}).then(results => {

              this.searchResults = results.data.data;
            });
            }
              else
              {this.searchResults = [];}

              },
        },
        watch:{
            // eslint-disable-next-line no-unused-vars
            $route (_to, from){
                if(this.$router.currentRoute.name.startsWith("wellness"))
                    this.openKeys = ['wellnessstat'];
            }
        }
}
</script>
<style>
.searchoutput
{position: absolute;
    top: 43px;
    z-index: 9999;
    display:none;
    overflow-y:scroll;
    max-height:60vh;
    background-color: #fff;
    }
    .navbar-search:hover .searchoutput
    {
      display:block;
    }
</style>
