<template>
  <div class="c-app">
    <TheSidebar v-if="navigation" />
    <CWrapper>
      <TheHeader />
      <div class="c-body">
        <main class="c-main">
          <CContainer fluid>
            <transition name="fade" mode="out-in">
              <router-view :key="$route.path"></router-view>
            </transition>
          </CContainer>
        </main>
      </div>
      <TheFooter />
    </CWrapper>
  </div>
</template>

<script>
import TheSidebar from './TheSidebar';
import TheHeader from './TheHeader';
import TheFooter from './TheFooter';
import { dataService } from "../_services";

export default {
  name: 'TheContainer',
  data() {
    return {
      paths: [],
      nav: [
        {
          _name: 'CSidebarNav',
          _children: [
            {
              _name: 'CSidebarNavItem',
              name: 'Nástenka',
              to: '/dashboard',
              permission: 'dashboard',
              icon: 'cil-speedometer',
            },
            {
              _name: 'CSidebarNavTitle',
              _children: ['Eshop']
            },
            {
              _name: 'CSidebarNavItem',
              name: 'Produkty',
              to: '/content/product',
              permission: 'product',
              icon: 'cil-calculator'
            },
            {
              _name: 'CSidebarNavItem',
              name: 'Objednávky',
              to: '/orders',
              permission: 'orders',
              icon: 'cil-list'
            },
            {
              _name: 'CSidebarNavItem',
              name: 'Faktúry',
              to: '/invoices',
              permission: 'invoices',
              icon: 'cil-envelope-closed'
            },
            {
              _name: 'CSidebarNavItem',
              name: 'Zákazníci',
              to: '/users',
              permission: 'users',
              icon: 'cil-people'
            },
            {
              _name: 'CSidebarNavTitle',
              _children: ['Web']
            },
            {
              _name: 'CSidebarNavDropdown',
              name: 'Obsah',
              icon: 'cil-puzzle',
            },
            {
              _name: 'CSidebarNavDropdown',
              name: 'Vzhľad',
              icon: 'cil-drop',
              items: [
                {
                  name: 'Bloky',
                  to: '/blocks',
                  permission: 'blocks'
                },
                {
                  name: 'Menu',
                  to: '/menu',
                  permission: 'content-menu'
                },
              ]
            },
            {
              _name: 'CSidebarNavDropdown',
              name: 'Nastavenia',
              icon: 'cil-settings',
              items: [
                {
                  name: 'Slovníky',
                  to: '/taxonomy',
                  permission: 'taxonomy'
                },
                {
                  name: 'Šablóny',
                  to: '/template',
                  permission: 'template'
                },
                {
                  name: 'Nastavenia',
                  to: '/webinfo',
                  permission: 'webinfo'
                },
                {
                  name: 'Kontá',
                  to: '/roles',
                  permission: 'roles'
                },
                {
                  name: 'Typy obsahu',
                  to: '/content-types',
                  permission: 'content-types'
                },
                {
                  name: 'Formuláre',
                  to: '/forms',
                  permission: 'forms'
                },
                {
                  name: 'SEO',
                  to: '/seo',
                  permission: 'seo'
                }
              ]
            }
          ],
        }
      ],
      navigation: false
    };
  },
  components: {
    TheSidebar,
    TheHeader,
    TheFooter
  },
  mounted() {
    const loggedIn = localStorage.getItem('user');
    const user = JSON.parse(loggedIn);
    this.paths = user.data.paths;

    // Check if ctypes exist in localStorage
    const savedNavigation = localStorage.getItem('ctypes');
    if (savedNavigation) {
      // If ctypes exist, load from localStorage
      this.navigation = JSON.parse(savedNavigation);
    } else {
      // Otherwise, fetch content types and filter the navigation
      dataService.axiosFetch("contenttypes").then(results => {
        this.contenttypes = results;

        // Dynamically add content types to the navigation
        this.nav[0]._children[7].items = [];
        this.contenttypes.forEach(obj => {
          this.nav[0]._children[7].items.push({ name: obj.title, to: '/content/' + obj.slug });
        });

        // Filter the navigation based on paths
        this.navigation = this.filterNav(this.nav, this.paths);
        // Save the filtered navigation to localStorage
        localStorage.setItem('ctypes', JSON.stringify(this.navigation));
      });
    }
  },
  methods: {
    filterNav(navItems, paths) {
      return navItems
        .map(item => {
          // If item has a permission, check if it should be retained
          if (item.permission && !paths.includes(item.permission)) {
            return null; // Remove the item if permission is not in paths
          }

          // Filter children recursively if they exist
          if (item._children) {
            const filteredChildren = this.filterNav(item._children, paths);
            if (filteredChildren.length === 0) {
              return null; // Remove parent if no children remain after filtering
            }
            return { ...item, _children: filteredChildren }; // Return item with filtered children
          }

          // Filter items in dropdowns if they exist
          if (item.items) {
            const filteredItems = this.filterNav(item.items, paths);
            if (filteredItems.length === 0) {
              return null; // Remove parent if no items remain after filtering
            }
            return { ...item, items: filteredItems }; // Return item with filtered items
          }

          // Return item if no filtering is necessary
          return item;
        })
        .filter(item => item !== null); // Remove null items after filtering
    }
  }
};
</script>


<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
